<template>
    <div
        class="overflow-y-auto mx-0 px-0 py-0 mt-3"
        :style="`height:${this.height}px`"
    >
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    @click:row="openProject"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                            style="background-color: #eeeeee !important"
                        >
                            <v-toolbar-title>PROJECTS</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <!-- Headers -->

                    <template v-slot:[`header.reference`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectReference"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.client`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectClient"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.name`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectName"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.accountManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectAManager"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.projectManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectPManager"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.city`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectCity"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.collaborators`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="projectCollaborator"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <!-- Items -->

                    <template v-slot:[`item.accountManager`]="{ item }">
                        <div class="my-4">
                            {{ item.accountManagerName }}
                        </div>
                    </template>
                    <template v-slot:[`item.projectManager`]="{ item }">
                        <div class="my-4">
                            {{ item.projectManagerName }}
                        </div>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                        <div class="my-4">
                            {{ item.clientName }}
                        </div>
                    </template>
                    <template v-slot:[`item.collaborators`]="{ item }">
                        <div
                            v-for="(collaborator,
                            index) of item.dataCollaborators"
                            :key="index"
                            class="my-4"
                        >
                            <p
                                v-if="
                                    collaborator != item.projectManagerName &&
                                        collaborator != item.accountManagerName
                                "
                            >
                                {{ collaborator }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <div class="my-4">
                            <div class="my-4">
                                {{ formatCurrency(item) }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <div class="d-flex justify-center my-4">
                            <v-btn
                                icon
                                small
                                @click.stop="openRestoreProjectDialog(item)"
                                :disabled="loading"
                            >
                                <v-icon small>mdi-archive-arrow-up</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                small
                                @click.stop="openDeleteDialog(item)"
                                :disabled="loading"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- DELETE PROJECT -->
        <v-dialog v-model="deleteDialog" persistent max-width="400px">
            <v-card :loading="loading">
                <v-card-title class="text-h5">Delete Project</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this project?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteProject"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- project  -->
        <v-dialog
            :retain-focus="false"
            v-model="viewProject"
            fullscreen
            ref="dialogQuotesBySection"
        >
            <v-card class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToProjects">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ selectedProject.name }}
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Restore Project Dialog -->
        <v-dialog v-model="restoreProjectDialog" persistent max-width="400px">
            <v-card :loading="loading">
                <v-card-title class="text-h5">Restore Project</v-card-title>
                <v-card-text>
                    Are you sure you want to restore and move back the project to the main board?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDialog">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="restoreProject"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Return Project
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations, mapGetters } from 'vuex'

export default {
    name: 'Projects',
    data: () => ({
        headers: [
            {
                text: 'Reference',
                value: 'reference',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Client',
                value: 'client',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Account M.',
                value: 'accountManager',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Project M.',
                value: 'projectManager',
                align: 'center',
                sortable: false,
            },
            {
                text: 'City',
                value: 'city',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Collaborators',
                value: 'collaborators',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Price',
                value: 'price',
                align: 'center',
            },
            {
                text: 'Action',
                value: 'action',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        projects: [],
        loading: false,
        quotes: [],
        users: [],
        clients: [],
        projectDialog: false,
        createProject: false,
        selectedProject: {},
        heightPopUp: 0,
        viewProject: false,
        dialogQuote: false,
        costs: undefined,
        settings: undefined,
        awardedQuotes: [],
        projectClient: undefined,
        projectName: undefined,
        projectPManager: undefined,
        projectAManager: undefined,
        projectReference: undefined,
        projectCity: undefined,
        projectCollaborator: undefined,
        projectQuote: undefined,
        deleteDialog: false,
        deleteError: false,
        errorMessage: undefined,
        error: false,
        restoreProjectDialog: false,
    }),
    async mounted() {
        try {
            this.loading = true
            this.error = false
            this.errorMessage = ''
            this.projects = await API.getProjects({ archive: true })
            const {
                data: { quotesHistory },
            } = await API.getHistoryQuotes()
            this.awardedQuotes = quotesHistory.filter(
                q => q.status === 'AWARDED'
            )
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
            const {
                data: { clients },
            } = await API.getClients()
            this.clients = clients
            const {
                data: { settings },
            } = await API.getSettings()
            let orderSettings = settings.filter(x => x.name == 'Company')
            orderSettings[1] = settings.find(x => x.name == 'Exchange')
            orderSettings[2] = settings.find(x => x.name == 'Permissions')
            orderSettings[3] = settings.find(x => x.name == 'Quotes')
            this.settings = orderSettings
            this.completeProjectData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
            if (this.getProjectId) {
                const project = this.projects.find(
                    p => p.id == this.getProjectId
                )
                if (project) {
                    this.openProject(project)
                }
            }
            this.setProjectId(undefined)
        }
    },
    updated() {
        this.onResize()
    },
    provide() {
        return {
            openQuoteFromProjects: this.openQuoteFromProjects,
        }
    },
    computed: {
        ...mapGetters(['getProjectId']),
        filters() {
            let conditions = []

            if (this.projectClient) {
                conditions.push(this.filterClient)
            }

            if (this.projectName) {
                conditions.push(this.filterName)
            }

            if (this.projectAManager) {
                conditions.push(this.filterAManager)
            }

            if (this.projectPManager) {
                conditions.push(this.filterPManager)
            }

            if (this.projectReference) {
                conditions.push(this.filterReference)
            }

            if (this.projectCity) {
                conditions.push(this.filterCity)
            }

            if (this.projectCollaborator) {
                conditions.push(this.filterCollaborators)
            }

            if (conditions.length > 0) {
                return this.projects.filter(project => {
                    return conditions.every(condition => {
                        return condition(project)
                    })
                })
            }
            return this.projects
        },
    },
    methods: {
        ...mapMutations(['setErrorItems', 'setProjectId']),
        filterCollaborators(item) {
            let res = false
            for (let i = 0; i < item.dataCollaborators.length; i++) {
                let collaborator = item.dataCollaborators[i]
                if (collaborator) {
                    res = item.dataCollaborators[i]
                        .toLowerCase()
                        .includes(this.projectCollaborator.toLowerCase())
                }
                if (res) break
            }
            return res
        },
        filterCity(item) {
            if (item.city) {
                return item.city
                    .toLowerCase()
                    .includes(this.projectCity.toLowerCase())
            } else {
                return false
            }
        },
        filterReference(item) {
            if (item.reference) {
                return item.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectReference.toLowerCase())
            } else {
                return false
            }
        },
        filterPManager(item) {
            if (item.projectManager) {
                return item.projectManagerName
                    .toLowerCase()
                    .includes(this.projectPManager.toLowerCase())
            } else {
                return false
            }
        },
        filterAManager(item) {
            if (item.accountManager) {
                return item.accountManagerName
                    .toLowerCase()
                    .includes(this.projectAManager.toLowerCase())
            } else {
                return false
            }
        },
        filterName(item) {
            if (item.name) {
                return item.name
                    .toLowerCase()
                    .includes(this.projectName.toLowerCase())
            } else {
                return false
            }
        },
        filterClient(item) {
            if (item.clientName) {
                return item.clientName
                    .toLowerCase()
                    .includes(this.projectClient.toLowerCase())
            } else {
                return false
            }
        },
        completeProjectData() {
            for (let project of this.projects) {
                project.clientName = this.getClientName(project.client)
                project.accountManagerName = this.getUserName(
                    project.accountManager
                )
                project.projectManagerName = this.getUserName(
                    project.projectManager
                )
                project.dataCollaborators = project.collaborators.map(
                    collaborator => {
                        return this.getUserName(collaborator)
                    }
                )
                project.dataQuotes = project.quotes.map(quote => {
                    return this.getQuoteName(quote)
                })
            }
        },
        async openQuoteFromProjects(quoteId, costs) {
            this.backToProjects()
            const quote = this.awardedQuotes.find(quote => quote.id === quoteId)
            await this.openQuote(quote, costs)
        },
        async openQuote(quote, costs) {
            this.dialogQuote = true
            this.costs = costs
            this.$router.replace({
                name:
                    quote.status !== 'SENT'
                        ? 'quoteGIProject'
                        : 'quoteFollowUpProject',
                params: {
                    id: quote.id,
                    quote,
                    settings: this.settings,
                },
            })
        },
        backToProjects() {
            this.dialogQuote = false
            this.viewProject = false
            this.$router
                .replace({
                    name: 'archive-projects',
                })
                .catch(error => error && null)
        },
        openProject(project) {
            this.viewProject = true
            this.selectedProject = _.cloneDeep(project)
            this.$router.push({
                name: 'archive-project',
                params: {
                    id: project.id,
                    project,
                    quotes: this.awardedQuotes,
                    clients: this.clients,
                    users: this.users,
                },
            })
        },
        editProject(project) {
            this.selectedProject = _.cloneDeep(project)
            this.createProject = false
            this.projectDialog = true
        },
        getQuoteName(id) {
            const quoteId = id.split('/')[0]
            if (this.awardedQuotes.length > 0) {
                return this.awardedQuotes.find(quote => quote.id == quoteId)
                    ?.name
            } else {
                return ''
            }
        },
        getUserName(id) {
            if (this.users.length > 0) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
        closeDialog() {
            this.selectedProject = {}
            this.projectDialog = false
            this.restoreProjectDialog = false
        },
        addProject(project) {
            this.projects.push(project)
            this.completeProjectData()
        },
        replaceProject(id, changes) {
            const index = this.projects.findIndex(p => p.id === id)
            if (index >= 0) {
                this.projects.splice(
                    index,
                    1,
                    Object.assign(this.projects[index], changes)
                )
            }
            this.completeProjectData()
        },
        removeProject(id) {
            this.projects = this.projects.filter(p => p.id != id)
        },
        formatCurrency(item) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: item.currency,
                maximumFractionDigits: 2,
            }).format(item.price)
        },
        deleteProject: async function() {
            try {
                this.loading = true
                this.deleteError = false
                this.errorMsg = null
                const { id } = this.selectedProject
                await API.deleteProject(id)
                this.removeProject(id)
                this.selectedProject = undefined
                this.deleteDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openDeleteDialog(item) {
            this.selectedProject = _.cloneDeep(item)
            this.deleteDialog = true
        },
        async restoreProject() {
            try {
                this.loading = true
                this.error = false
                this.errorMessage = ''
                await API.updateProject(this.selectedProject.id, { archive: false })
                this.removeProject(this.selectedProject.id)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openRestoreProjectDialog(project) {
            this.selectedProject = _.cloneDeep(project)
            this.restoreProjectDialog = true
        }
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
.v-data-table >>> thead {
    background-color: #eeeeee;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
